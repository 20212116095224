import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import Carousel from './carousel'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import styles from './timeline.module.css'

function Page (props) {
  const {_rawBody, gallery, title, mainImage} = props
  return (
    <article className={styles.root}>
      {/* <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/timeline/">
            Linha do tempo
          </Breadcrumb.Item>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb> */}
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1920)
              .height(Math.floor((9 / 16) * 1920))
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
       
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
              <div className={styles.portable}>{_rawBody && <PortableText blocks={_rawBody} />}</div>
          </div>
        </div>
      </Container>

      <div className={styles.carousels}>
        <div className={styles.galeria}>
          {gallery && <Carousel data={gallery}/>}
        </div>
      </div>

    </article>
  )
}

export default Page
